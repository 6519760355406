import React from 'react'
import frontendImg from '../../assets/images/front-end.png'
import backendImg from '../../assets/images/backend.png'
import uiImg from '../../assets/images/design.png'
import appsdImg from '../../assets/images/apps.png'


const Services = () => {
  return (
    <section className="" id='services'>
        <div className="container lg:pt-5 ">
            <div className="text-center ">
                <h2 className="text-headingColor font-[800] text-[2.4rem] mb-5">What can I do ?</h2>
                <p className="lg:max-w-[600px] lg:mx-auto text-headingColor font-[500] text-[16px] leading-7 ">
                You want a website. It must look great and you want it to work... All the time. Well, that's why I'm here! I help you by designing, building, and hosting a beautiful site that'll grow your business.
                </p>
            </div>
            <div className="flex flex-col justify-center sm:py-12">
                <div className="w-full py-3 px-2 sm:max-w-xl sm:mx-auto sm:px-0">
                    <div className="relative text-gray-700 antialiased text-sm font-semibold">
                        {/*===============Vertical line running through the middel =================== */}
                            <div className="hidden absolute w-1 sm:block bg-indigo-300 h-full left-1/2 transform -translate-x-1/2 "></div>

                            {/*================ Left Card================ */}
                                <div className="mt-6 sm:mt-0 sm:mb-12 ">
                                   <div className="flex items-center flex-col sm:flex-row ">
                                       
                                        <div className="flex justify-start w-full mx-auto items-center ">
                                            <div className="w-full sm:w-1/2 sm:pr-8">
                                                <div data-aos='fade-right' data-aos-duration='1400' data-aos-delay='100'  className="bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150">
                                                    <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl ">Frontend Development</h3>
                                                    <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] leading-7">
                                                    I build beautiful, conversion-focused websites to communicate your powerful message to the world. A website that communicates their powerful message to the world and helps more clients find them.</p>

                                                </div>
                                            
                                        </div>
                                    </div>

                                    <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center ">
                                        <figure className="">
                                            <img src={frontendImg} alt="" className="" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            {/*================ Left Card================ */}

                            {/*========Right Card ==========*/}

                            <div className="mt-6 sm:mt-0 sm:mb-12 ">
                                   <div className="flex items-center flex-col sm:flex-row ">
                                       
                                        <div className="flex justify-end w-full mx-auto items-center ">
                                            <div className="w-full sm:w-1/2 sm:pl-8">
                                                <div data-aos='fade-left' data-aos-delay='50' data-aos-duration='1300'  className="bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150">
                                                    <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl ">Backend Development</h3>
                                                    <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] leading-7">   Your website isn’t a business card. It’s a story.
At least, it should be!
The kind of story that grabs attention, sparks emotion, and helps people see what’s possible..</p>

                                                </div>
                                            
                                        </div>
                                    </div>

                                    <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center ">
                                        <figure className="">
                                            <img src={backendImg} alt="" className="" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            {/*====Rigth Card ====}

                             {/*================ Left Card================ */}
                                <div className="mt-6 sm:mt-0 sm:mb-12 ">
                                   <div className="flex items-center flex-col sm:flex-row ">
                                       
                                        <div className="flex justify-start w-full mx-auto items-center ">
                                            <div className="w-full sm:w-1/2 sm:pr-8">
                                                <div data-aos='fade-right' data-aos-duration='1200'  className="bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150">
                                                    <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl ">UI/UX Design</h3>
                                                    <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] leading-7">      User Experience Design
    Rapid Prototyping
    Usability Testing
    UX Reviews
    Interaction Design
    Customer journeys .</p>

                                                </div>
                                            
                                        </div>
                                    </div>

                                    <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center ">
                                        <figure className="">
                                            <img src={uiImg} alt="" className="" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            {/*================ Left Card================ */}

                            {/*========Right Card ==========*/}

                            <div className="mt-6 sm:mt-0 sm:mb-12 ">
                                   <div className="flex items-center flex-col sm:flex-row ">
                                       
                                        <div className="flex justify-end w-full mx-auto items-center ">
                                            <div className="w-full sm:w-1/2 sm:pl-8">
                                                <div data-aos='fade-left' data-aos-delay='50' data-aos-duration='1300'  className="bg-white p-4 rounded shadow group hover:bg-primaryColor cursor-pointer ease-in duration-150">
                                                    <h3 className="text-primaryColor font-[700] mb-3 group-hover:text-white group-hover:font-[600] text-xl ">Apps Development</h3>
                                                    <p className="text-[15px] text-smallTextColor group-hover:text-white group-hover:font-[500] leading-7">      Front-end development
    Responsive & Mobile Websites
    Mobile apps
    Animations
    SaaS applications
    Platform solutions.</p>

                                                </div>
                                            
                                        </div>
                                    </div>

                                    <div className="rounded-full bg-primaryColor border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-4 sm:translate-y-0 flex items-center justify-center ">
                                        <figure className="">
                                            <img src={appsdImg} alt="" className="" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            {/*====Rigth Card ====}
                        {/*===============Vertical line running through the middel =================== */}
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Services